
(function ($) {
    var plugin_bioNew = this;
    plugin_bioNew.settings = {}
    plugin_bioNew.processingRequest = false;
    plugin_bioNew.searchString = "";
    plugin_bioNew.officeFilter = "";
    plugin_bioNew.searchCategory = "";
    plugin_bioNew.searchSubCategory = "";

    plugin_bioNew.publicationType = "";
    plugin_bioNew.publicationYear = "";

    plugin_bioNew.displayValue = "";
    plugin_bioNew.subDisplayValue = "";

    plugin_bioNew.sector = "";
    plugin_bioNew.practice = "";
    plugin_bioNew.region = "";
    plugin_bioNew.subSector = "";
    plugin_bioNew.subService = "";
    plugin_bioNew.subRegion = "";
    plugin_bioNew.officeLocation = "";
    plugin_bioNew.newsPubLocation = "";
    plugin_bioNew.eventsLocation = "";
    plugin_bioNew.filterGroup = "";

    plugin_bioNew.enforcelanguage = "";
    plugin_bioNew.languagetoenforce = "";

    plugin_bioNew.loadCategories = false;

    plugin_bioNew.currIndexValue = "";
    plugin_bioNew.isSectorClicked = false;
    plugin_bioNew.isServiceClicked = false;
    plugin_bioNew.isRegionClicked = false;
    plugin_bioNew.hideGroup = "";
    plugin_bioNew.clickedGroup = "";

    plugin_bioNew.loadFacets = true;

    plugin_bioNew.datasource = {};

    plugin_bioNew.pagingInfo = {};
    plugin_bioNew.pagingInfo.currentPage = 1;
    plugin_bioNew.pagingInfo.isLoadPagination = true;

    plugin_bioNew.pagingInfo.pagingLastCount = "";
    plugin_bioNew.pagingInfo.pagingFirstCount = "";
    plugin_bioNew.pagingInfo.pagingNextCount = "";
    plugin_bioNew.pagingInfo.pagingPreviousCount = "";

    plugin_bioNew.pagingInfo.isLastClick = false;
    plugin_bioNew.pagingInfo.isFirstClick = false;
    plugin_bioNew.pagingInfo.isNextClick = false;
    plugin_bioNew.pagingInfo.isPreviousClick = false;
    plugin_bioNew.pagingInfo.isSortingClick = false;


    $.globalSearch1 = function (element, options) {
        /* defaults */
        var defaults = {
            pageNumber: 0,
            itemId: "",
            loaderDiv: "#divProcessing",
            honorGroupCount: true
        };

        function ResetPagingInfo() {
            plugin_bioNew.pagingInfo.currentPage = 1;
            plugin_bioNew.pagingInfo.isLoadPagination = true;
            plugin_bioNew.pagingInfo.pagingLastCount = "";
            plugin_bioNew.pagingInfo.pagingFirstCount = "";
            plugin_bioNew.pagingInfo.pagingNextCount = "";
            plugin_bioNew.pagingInfo.pagingPreviousCount = "";
            plugin_bioNew.pagingInfo.isLastClick = false;
            plugin_bioNew.pagingInfo.isFirstClick = false;
            plugin_bioNew.pagingInfo.isNextClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = false;
            plugin_bioNew.pagingInfo.isViewAllClick = false;

        };

        /* models */
        var searchresult = function (title, description, url, articledate, personName, personImage, personDesignation, personURL, personService, personEmail, personPhone, personOfficeDisplay, personOfficePhone, personAlternateBio, searchItemID, currentLanguage, designationLanguage, locationLanguage) {
            this.Title = title,
            this.Description = description,
            this.Url = url,
            this.ArticleDate = articledate,

            this.PersonName = personName,
            this.PersonImage = personImage,
            this.PersonDesignation = personDesignation,
            this.PersonURL = personURL,
            this.PersonService = personService,
            this.PersonEmail = personEmail,
            this.Mailto = "mailto:" + personEmail,
            this.PersonPhone = personPhone,
            this.PersonOfficeDisplay = personOfficeDisplay,
            this.PersonOfficePhone = personOfficePhone,
            this.PersonBiosDisplay = personAlternateBio,
            this.CurrentLanguage = currentLanguage,
            this.DesignationLanguage = designationLanguage,
            this.LocationLanguage = locationLanguage,
            this.SearchItemID = "/VCard/DownloadVCard?itemId=" + decodeURIComponent(searchItemID);


        }
        var pagefacet = function (n, c, isLarge) {
            this.pageNo = n;
            this.applyCss = c;
            this.isLargeNumber = isLarge;
        }

        var category = function (name, count, datatitle, indexvalue, indexname) {
            this.FacetName = name,
            this.FacetCount = count,
            this.DataTitle = datatitle,
            this.IndexValue = indexvalue,
            this.IndexName = indexname;
        }


        /* init function */
        plugin_bioNew.init = function () {

            plugin_bioNew.settings = $.extend({}, defaults, options);

            var dataSeq = ['category', 'subcategory', 'publicationtype', 'year'];
            $('#sectionSubCategory').hide();

            //Get all query string paramaters
            var searchString = $.urlParam('SearchString');
            var siteContentCategory = $.urlParam('param_sitecontentcategory');
            var siteContentSubCategory = $.urlParam('param_sitecontentsubcategory');
            var sector = $.urlParam('sectors_sm');
            var practice = $.urlParam('practices_sm');
            var region = $.urlParam('region_sm');
            var officeLocation = $.urlParam('personofficeitem_sm');

            var subPractice = $.urlParam('sub_practices_sm');
            var subSector = $.urlParam('sub_sectors_sm');
            var subRegion = $.urlParam('sub_region_sm');
            var displayValue = $.urlParam('displayvalue');
            var subDisplayValue = $.urlParam('subdisplayvalue');

            if (searchString == "0") {
                searchString = $.urlParam('searchString');
            }


            if (siteContentCategory != null && siteContentCategory != '') {
                plugin_bioNew.loadCategories = true;
                plugin_bioNew.searchCategory = decodeURIComponent(siteContentCategory.split('+').join(' '));
                if (plugin_bioNew.searchCategory.toLowerCase() == "our people") {
                    $("#hBySubCategory").hide();
                } else {
                    $("#hBySubCategory").show();
                }
            }
            if (siteContentSubCategory != null && siteContentSubCategory != '') {
                plugin_bioNew.searchSubCategory = siteContentSubCategory;
            }
            if (sector != null && sector != '') {
                plugin_bioNew.sector = sector;
            }

            if (subSector != null && subSector != '') {
                plugin_bioNew.subSector = subSector;
            }
            if (subRegion != null && subRegion != '') {
                plugin_bioNew.subRegion = subRegion;
            }
            if (practice != null && practice != '') {
                plugin_bioNew.practice = practice;
            }
            if (region != null && region != '') {
                plugin_bioNew.region = region;
            }
            if (officeLocation != null && officeLocation != "") {
                plugin_bioNew.officeLocation = officeLocation;
            }
            if (subPractice != null && subPractice != '') {
                plugin_bioNew.subService = subPractice;
            }
            if (displayValue != null && displayValue != "") {
                plugin_bioNew.displayValue = decodeURIComponent(displayValue.split('+').join(' '));
            }
            if (subDisplayValue != null && subDisplayValue != "") {
                plugin_bioNew.subDisplayValue = decodeURIComponent(subDisplayValue.split('+').join(' '));
            }

            // SetPreviousData(qsField, txtJQueryField, IsEncoded)
            SetPreviousData('designation_s', '#designation_s', false);
            SetPreviousData('SearchString', '#SearchString', false);
            SetPreviousData('sectorServiceSearchString', '#sectorServiceSearchString', false);
            SetPreviousData('personschool_sm', '#personschool_sm', false);
            SetPreviousData('schoolsearchstring', '#schoolsearchstring', false);
            SetPreviousData('courtsearchstring', '#courtsearchstring', false);
            SetPreviousData('judgesearchstring', '#judgesearchstring', false);
            SetPreviousData('personclerkshipcourts_sm', '#personclerkshipcourts_sm', false);
            SetPreviousData('personclerkshipjudges_sm', '#personclerkshipjudges_sm', false);
            //SetPreviousData('isInternalBioRequest', '#isInternalBioRequest', false);
            //<input type="hidden" class="qsFields" name="isInternalBioRequest" id="isInternalBioRequest" value='' />
            SetPreviousData('groupName', '#groupName', false);

            var pagingNumber = 1;
            var pagingNumQS = $.urlParam('pagenum');
            if (pagingNumQS != 0) {
                setPagingInfoFromQS(pagingNumQS);
                pagingNumber = pagingNumQS;
                plugin_bioNew.pagingInfo.currentPage = pagingNumber;
            }

            selectDdlFromQS();
            createSelectionAreaFromQueryString();

            plugin_bioNew.loadSearchResults1(pagingNumber, "", true);


            plugin_bioNew.datasource.vm = {
                facets: ko.observableArray([]),
                subfacets: ko.observableArray([]),
                subfacetsThirdLevel: ko.observableArray([]),
                yearFacets: ko.observableArray([]),
                searchResults: ko.observableArray([]),
                total: ko.observable(""),
                /* if there spell check is true */
                searchResultCategory: ko.observable(""),
                searchText: ko.observable(""),
                /* for paging */
                pagingFacets: ko.observableArray([]),
                pageLastNo: ko.observable(""),
                pageFirstNo: ko.observable(""),
                loadPageData: plugin_bioNew.loadPagingResult,
                PageFirstClick: plugin_bioNew.pgFirstclick,
                PageLastClick: plugin_bioNew.pgLastclick,
                PageNextClick: plugin_bioNew.pgNextclick,
                PagePreviousClick: plugin_bioNew.pgPreviousclick,
                PageViewAllClick: plugin_bioNew.pgViewAllClick,
                PageBackToPaginationClick: plugin_bioNew.pgBackToPaginationClick
            };

            $(document).on("click", ".siteCategory", function () {

                filterClick($(this));

                plugin_bioNew.datasource.vm.searchResults.removeAll();
                plugin_bioNew.pagingInfo.isLoadPagination = true;
                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                plugin_bioNew.datasource.vm.subfacets.removeAll();
                plugin_bioNew.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin_bioNew.datasource.vm.yearFacets.removeAll();
                // $("#sectionThirdLevel").hide();
                //$("#sectionForthLevel").hide();

                plugin_bioNew.loadFacets = true;
                plugin_bioNew.searchCategory = $(this).attr('datatitle');

                plugin_bioNew.searchSubCategory = "";
                plugin_bioNew.loadCategories = false;
                //ResetSortDDLOption();
                plugin_bioNew.pagingInfo.isSortingClick = false;

                plugin_bioNew.loadSearchResults(1, "");

                if (plugin_bioNew.searchCategory.toLowerCase() == "our people") {
                    $("#hBySubCategory").hide();
                } else {
                    $("#hBySubCategory").show();
                }
            });

            $(document).on("click", ".siteSubCategory", function () {
                var name = $(this).attr('name');
                name = name.toLowerCase();

                plugin_bioNew.publicationType = "";
                plugin_bioNew.publicationYear = "";

                if ($(this).attr('parentGroup') != null) {
                    plugin_bioNew.clickedGroup = $(this).attr('parentGroup').toLowerCase();
                } else {
                    plugin_bioNew.clickedGroup = name;
                }
                plugin_bioNew.isSectorClicked = false;
                plugin_bioNew.isServiceClicked = false;
                plugin_bioNew.isRegionClicked = false;
                if (name.indexOf("sector") >= 0) {
                    plugin_bioNew.isSectorClicked = true;

                }
                else if (name.indexOf("service") >= 0) {
                    plugin_bioNew.isServiceClicked = true;

                }
                else if (name.indexOf("region") >= 0) {
                    plugin_bioNew.isRegionClicked = true;

                }

                filterClick($(this));

                plugin_bioNew.datasource.vm.searchResults.removeAll();
                //   plugin_bioNew.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin_bioNew.datasource.vm.yearFacets.removeAll();
                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                plugin_bioNew.loadFacets = true;

                plugin_bioNew.pagingInfo.isLoadPagination = true;
                plugin_bioNew.loadCategories = false;
                //$("#sectionThirdLevel").hide();
                //$("#sectionForthLevel").hide();
                if (plugin_bioNew.searchCategory.toLowerCase() != "our people") {
                    plugin_bioNew.searchSubCategory = $(this).attr('datatitle');

                }

                if ($(this).attr('parentid') != null) {
                    plugin_bioNew.currIndexValue = $(this).attr("parentid");
                    plugin_bioNew.filterGroup = $(this).attr('parentgroup');
                } else {
                    plugin_bioNew.currIndexValue = $(this).attr("indexvalue");
                    plugin_bioNew.filterGroup = $(this).data("sub");
                }


                //ResetSortDDLOption();
                plugin_bioNew.pagingInfo.isSortingClick = false;
                plugin_bioNew.loadSearchResults(1, "");

            });



            $(document).on("click", "span.crossBtn", function () {
                var dataType = $(this).attr('data-type');
                var currentIndexName = $(this).attr('indexname');
                var crossBtnGroup = $(this).parent().attr('class');
                var crossBtnParentId = $(this).attr('parentid');
                var crossBtnIndexValue = $(this).attr('indexvalue');
                var crossBtnParentGroup = $(this).attr('parentgroup');


                plugin_bioNew.datasource.vm.searchResults.removeAll();
                plugin_bioNew.pagingInfo.isLoadPagination = true;

                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                if (dataType == "category") {
                    plugin_bioNew.loadCategories = true;
                    plugin_bioNew.searchCategory = "";
                    plugin_bioNew.datasource.vm.facets.removeAll();
                    plugin_bioNew.loadFacets = true;
                    $('span.crossBtn').each(function (i) {
                        $(this).removeAttr('indexname');
                    });

                    plugin_bioNew.clickedGroup = "";

                } else {
                    $(this).removeAttr('indexname');
                }

                if (currentIndexName == "sitecontentsubcategory") {
                    plugin_bioNew.datasource.vm.subfacets.removeAll();
                }

                plugin_bioNew.searchSubCategory = "";
                plugin_bioNew.isSectorClicked = false;
                plugin_bioNew.isServiceClicked = false;
                plugin_bioNew.isRegionClicked = false;
                FilterRemove(dataType);

                if (dataType == "category") {
                    SubGroupFilterRemove("subsector");
                    SubGroupFilterRemove("subservice");
                    SubGroupFilterRemove("subregion");
                }



                var dataCounttemp = $(this).attr('data-count-theme');
                $('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
                $(this).parent().remove();
                if ($('.grp' + dataType).find("li").length == 0)
                    $('.grp' + dataType).remove();
                check();
                $('#sectionCategory').show();
                //  ResetSortDDLOption();
                plugin_bioNew.loadFacets = true;
                plugin_bioNew.pagingInfo.isSortingClick = false;

                if (crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find("." + crossBtnGroup).length == 0
                && crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find(".Sub" + crossBtnGroup).length == 0
                && crossBtnGroup != null && $('.selectedFilterVal > ul').find("ul").find("." + crossBtnGroup.replace("Sub", "")).length == 0) {

                    if (plugin_bioNew.clickedGroup == crossBtnGroup.toLowerCase()
                     || plugin_bioNew.clickedGroup == crossBtnGroup.substring(3).toLowerCase()) {
                        plugin_bioNew.clickedGroup = "";
                    }
                }

                if (crossBtnGroup != null) {
                    if (crossBtnIndexValue != null) {
                        if (crossBtnGroup.toLowerCase() == "sector" || crossBtnGroup.toLowerCase() == "service"
                        || crossBtnGroup.toLowerCase() == "region") {
                            crossBtnParentId = crossBtnIndexValue;
                            crossBtnParentGroup = crossBtnGroup;
                        }
                    }

                    if (crossBtnParentId != null) {
                        var mainGroupSize = $('.selectedFilterVal > ul').find("ul").find("span" + '[indexvalue=' + crossBtnParentId + ']').length;
                        var subGroupSize = $('.selectedFilterVal > ul').find("ul").find("span" + '[parentid=' + crossBtnParentId + ']').length;
                        //Display subgroups in filter criteria if there is any sub group in selection criteria or if there is main group aviable and no sub group aviable in selection criteria
                        if ((mainGroupSize > 0 && subGroupSize >= 0) || (mainGroupSize == 0 && subGroupSize > 0)) {
                            plugin_bioNew.currIndexValue = crossBtnParentId;
                            if (crossBtnParentGroup != null) {
                                if (crossBtnParentGroup.toLowerCase() == "sector") {
                                    plugin_bioNew.isSectorClicked = true;

                                } else if (crossBtnParentGroup.toLowerCase() == "service") {
                                    plugin_bioNew.isServiceClicked = true;

                                } else if (crossBtnParentGroup.toLowerCase() == "region") {
                                    plugin_bioNew.isRegionClicked = true;

                                }
                            }
                        }
                    }
                }
                var subCategoryTypeClicktxt = '';
                var currentGrouptxt = '';
                var filtergroupTxt = '';
                var lastIndexName = '';
                var lastIndexValue = '';
                $('span.crossBtn').each(function (i) {
                    if ($(this).attr('indexname')) {
                        lastIndexName = $(this).attr('indexname');
                        lastIndexValue = $(this).attr('indexvalue');
                        var indexparentId = $(this).attr('parentid');
                        if (indexparentId)
                        { lastIndexValue = indexparentId; };

                        //  filters[indexName].push($(this).attr('indexvalue'));
                    }
                });
                if (lastIndexValue && lastIndexName) {

                    var isFound = false;
                    switch (lastIndexName) {
                        case 'sub_sectors_sm':
                        case 'sectors_sm':
                            isFound = true;
                            plugin_bioNew.filterGroup = 'Sector';
                            break;
                        case 'sub_practices_sm':
                        case 'practices_sm':
                            isFound = true;
                            plugin_bioNew.filterGroup = 'Service';
                            break;
                        case 'sub_region_sm':
                        case 'region_sm':
                            isFound = true;
                            plugin_bioNew.filterGroup = 'Region';
                            break;
                        case 'personofficeitem_sm':
                            isFound = true;
                            plugin_bioNew.filterGroup = 'Office';
                            break;

                    }
                    if (isFound) {
                        plugin_bioNew.isSectorClicked = false;
                        plugin_bioNew.isServiceClicked = false;
                        plugin_bioNew.isRegionClicked = false;
                        plugin_bioNew.currIndexValue = lastIndexValue;
                        switch (plugin_bioNew.filterGroup) {
                            case 'Sector':
                                plugin_bioNew.isSectorClicked = true;
                                break;
                            case 'Service':
                                plugin_bioNew.isServiceClicked = true;
                                break;
                            case 'Region':
                                plugin_bioNew.isRegionClicked = true;
                                break;
                        }
                    }
                }
                plugin_bioNew.loadSearchResults(1, "");

            });

            $(document).on("click", ".clear-all-filter", function () {
                var dataType = $(this).attr('data-type');
                plugin_bioNew.loadFacets = true;
                plugin_bioNew.datasource.vm.searchResults.removeAll();
                plugin_bioNew.datasource.vm.facets.removeAll();
                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                plugin_bioNew.datasource.vm.subfacets.removeAll();
                //     plugin_bioNew.datasource.vm.subfacetsThirdLevel.removeAll();
                plugin_bioNew.datasource.vm.yearFacets.removeAll();
                // plugin_bioNew.searchCategory = "";
                plugin_bioNew.isSectorClicked = false;
                plugin_bioNew.isServiceClicked = false;
                plugin_bioNew.isRegionClicked = false;
                plugin_bioNew.currIndexValue = '';
                plugin_bioNew.searchSubCategory = "";
                plugin_bioNew.clickedGroup = "";
                plugin_bioNew.filterGroup = '';
                $("#refineResults").show();
                FilterRemove(dataType);
                $('#sectionCategory').show();
                ResetSortDDLOption();
                $('.qsFields').each(function (i) {
                    if ($(this).val() && $(this).val() != '')
                    { $(this).val(''); }
                });
                plugin_bioNew.pagingInfo.isSortingClick = false;
                plugin_bioNew.loadSearchResults(1, "");
            });

            /* start */
            $(document).on("click", "#sectionSubCategory ul#ulPersonFacets li span.subCat", function () {
                if ($(this).hasClass("expand")) {
                    $(this).removeClass("expand");
                    $(this).parent().find("ul.subCatItem").slideUp();
                } else {

                    $("#sectionSubCategory ul#ulPersonFacets li span.subCat").removeClass("expand");
                    $("ul.subCatItem").hide();
                    $(this).parent().find("ul.subCatItem").slideDown();
                    $(this).addClass("expand");
                    $('html,body').scrollTop($(this).offset().top - 100);

                }
            });
            /* end */

            function setPagingInfoFromQS(pagingNumQS) {
                var pagingchangeclickQS = $.urlParam('pagingchangeclick');
                if (pagingchangeclickQS != 0) {
                    switch (pagingchangeclickQS) {
                        case 'isLastClick':
                            plugin_bioNew.pagingInfo.isLastClick = true;
                            break;
                        case 'isFirstClick':
                            plugin_bioNew.pagingInfo.isFirstClick = true;
                            break;
                        case 'isNextClick':
                            plugin_bioNew.pagingInfo.isNextClick = true;
                            plugin_bioNew.pagingInfo.pagingNextCount = pagingNumQS;
                            break;
                        case 'isPreviousClick':
                            plugin_bioNew.pagingInfo.isPreviousClick = true;
                            plugin_bioNew.pagingInfo.pagingPreviousCount = pagingNumQS;
                            break;
                    }
                }
            }

            function SetPreviousData(qsField, txtJQueryField, IsEncoded) {
                var qsFieldValue = $.urlParam(qsField);
                if (qsFieldValue != 0) {
                    if (IsEncoded) { qsFieldValue = decodeURIComponent(qsFieldValue.split('+').join(' ')); }
                    $(txtJQueryField).val(qsFieldValue);
                }
            }

            function selectDdlFromQS() {

                //page size
                var pagesizeQS = $.urlParam('pagesize');
                if (pagesizeQS != 0) {
                    $("#ddlPageSize_Global").val(pagesizeQS);
                    var userTextPagesize = $("#ddlPageSize_Global option:selected").text();
                    $('#oldSelectedPageSize_Global').val($("#ddlPageSize_Global option:selected").val());
                    $(".sortSel .main #ulddlPageSize_Global .stylish-select-left .stylish-select-right").text(userTextPagesize);
                }
                else {
                    var ddlName = 'ddlPageSize_Global';
                    $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                    var userText = $('#' + ddlName).val();
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                    $('#oldSelectedPageSize_Global').val($('#' + ddlName + '').val());
                    $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                    $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
                }

                var isRelatedLinkQs = $.urlParam('isRelatedLink');
                if (isRelatedLinkQs != 0 && isRelatedLinkQs) {
                    $('#isRelatedLink').val(isRelatedLinkQs);
                    $("#generalOptions option[value='Title']").remove()
                    $("#generalOptions").append(new Option('Position', 'Position'));
                }
                var sortingqsQS = $.urlParam('sortingqs');
                if (sortingqsQS != 0) {
                    sortingqsQS = decodeURIComponent(sortingqsQS);
                    $("#generalOptions").val(sortingqsQS);
                    var userTextPagesize = $("#generalOptions option:selected").text();
                    $('#GlobleSearchSortingValue').val($("#generalOptions option:selected").val());
                    $(".sortSel .main #ulgeneralOptions .stylish-select-left .stylish-select-right").text(userTextPagesize);
                }
                else {
                    var ddlName = 'generalOptions';
                    $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                    var userText = $('#' + ddlName).val();
                    $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                    // $('#GlobleSearchSortingValue').val($('#' + ddlName + '').val());
                    $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                    $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
                }
            }


            //This will be in use when user come from related resource section
            function createSelectionAreaFromQueryString() {

                var subCattxt = $.urlParam('subCatText');
                var subCatInfo = $.urlParam('subCatInfo');
                if (subCattxt != 0 && subCatInfo != 0) {
                    var arrSubCatinfo = subCatInfo.split(',');
                    var arrSubCattxt = subCattxt.split(',');
                    subCatReadFromQS(arrSubCattxt, arrSubCatinfo);
                }

                var subServiceText = $.urlParam('subServiceText');
                var subServiceInfo = $.urlParam('subServiceInfo');
                if (subServiceText != 0 && subServiceInfo != 0) {
                    var arrsubServiceInfo = subServiceInfo.split(',');
                    var arrsubServiceText = subServiceText.split(',');
                    //AllSubPracticeReadFromQS(arrSubPracDispTxt, arrSubPracIdAndParentID, subPracticClass, parentGrp)
                    AllSubPracticeReadFromQS(arrsubServiceText, arrsubServiceInfo, 'SubService', 'service')
                }

                var subSectorText = $.urlParam('subSectorText');
                var subSectorInfo = $.urlParam('subSectorInfo');
                if (subSectorText != 0 && subSectorInfo != 0) {
                    var arrsubSectorInfo = subSectorInfo.split(',');
                    var arrsubSectorText = subSectorText.split(',');
                    //AllSubPracticeReadFromQS(arrSubPracDispTxt, arrSubPracIdAndParentID, subPracticClass, parentGrp)
                    AllSubPracticeReadFromQS(arrsubSectorText, arrsubSectorInfo, 'SubSector', 'sector')
                }
                var subRegionText = $.urlParam('subRegionText');
                var subRegionInfo = $.urlParam('subRegionInfo');
                if (subRegionText != 0 && subRegionInfo != 0) {
                    var arrsubRegionInfo = subRegionInfo.split(',');
                    var arrsubRegionText = subRegionText.split(',');
                    //AllSubPracticeReadFromQS(arrSubPracDispTxt, arrSubPracIdAndParentID, subPracticClass, parentGrp)
                    AllSubPracticeReadFromQS(arrsubRegionText, arrsubRegionInfo, 'SubRegion', 'region')
                }

                var subCategoryTypeClickid = $.urlParam('subCategoryTypeClick');
                if (subCategoryTypeClickid != 0) {
                    switch (subCategoryTypeClickid) {
                        case 'isSectorClick':
                            plugin_bioNew.isSectorClicked = true;
                            break;
                        case 'isServiceClick':
                            plugin_bioNew.isServiceClicked = true;
                            break;
                        case 'isRegionClick':
                            plugin_bioNew.isRegionClicked = true;
                            break;
                    }
                }
                var currentGroupId = $.urlParam('currentGroup');
                if (currentGroupId != 0) {
                    plugin_bioNew.currIndexValue = currentGroupId;
                }
                var filterGroup = $.urlParam('filtergroup');
                if (filterGroup != 0 && filterGroup != '') {
                    plugin_bioNew.clickedGroup = filterGroup.toLowerCase();
                    plugin_bioNew.filterGroup = filterGroup;
                }

                //currIndexValue
                var enforcelanguage = $.urlParam('enforce_language');
                var languagetoenforce = $.urlParam('language_to_enforce');

                if (enforcelanguage != null && enforcelanguage != '') {
                    plugin_bioNew.enforcelanguage = decodeURIComponent(enforcelanguage);
                }

                if (languagetoenforce != null && languagetoenforce != '') {
                    plugin_bioNew.languagetoenforce = decodeURIComponent(languagetoenforce);
                }

                check();
            }

            function querystringForAjax(pagenum) {
                var query = [];
                if (pagenum) {
                    query.push("pagenum=" + pagenum);
                }
                var sortingDivOption = $('#GlobleSearchSortingValue').val();
                if (sortingDivOption) {
                    query.push("sortingqs=" + sortingDivOption);
                }
                //for page size
                var pageSizeValue = $('#oldSelectedPageSize_Global').val();
                if (pageSizeValue != null && pageSizeValue != undefined) {
                    query.push("pagesize=" + pageSizeValue);
                }

                if (plugin_bioNew.pagingInfo.isLastClick) {
                    query.push("isLastClick=true");
                }
                if (plugin_bioNew.pagingInfo.isFirstClick) {
                    query.push("isFirstClick=true");
                }
                if (plugin_bioNew.pagingInfo.isNextClick) {
                    query.push("isNextClick=true");
                }
                if (plugin_bioNew.pagingInfo.isPreviousClick) {
                    query.push("isPreviousClick=true");
                }
                if (plugin_bioNew.pagingInfo.isViewAllClick) {
                    query.push("isViewAllClick=true");
                }
                if (plugin_bioNew.isSectorClicked) {
                    query.push("isSectorClick=true");
                }
                if (plugin_bioNew.isServiceClicked) {
                    query.push("isServiceClick=true");
                }
                if (plugin_bioNew.isRegionClicked) {
                    query.push("isRegionClick=true");
                }
                if (plugin_bioNew.currIndexValue != null && plugin_bioNew.currIndexValue != "") {
                    query.push("currentGroup=" + plugin_bioNew.currIndexValue);
                }

                if (plugin_bioNew.enforcelanguage != null && plugin_bioNew.enforcelanguage != "") {
                    query.push("enforce_language=" + plugin_bioNew.enforcelanguage);
                }

                if (plugin_bioNew.languagetoenforce != null && plugin_bioNew.languagetoenforce != "") {
                    query.push("language_to_enforce=" + plugin_bioNew.languagetoenforce);
                }
                if (plugin_bioNew.loadCategories) {
                    query.push("loadCategories=true");
                }
                if (plugin_bioNew.searchCategory != null && plugin_bioNew.searchCategory != "") {
                    query.push("param_sitecontentcategory=" + encodeURIComponent(plugin_bioNew.searchCategory));
                }
                if (plugin_bioNew.searchSubCategory != null && plugin_bioNew.searchSubCategory != "") {
                    query.push("param_sitecontentsubcategory=" + encodeURIComponent(plugin_bioNew.searchSubCategory));
                }
                $('.qsFields').each(function (i) {
                    if ($(this).val() && $(this).val() != '')
                    { query.push($(this).attr('id') + "=" + $(this).val()); }
                });

                return url + query.join("&") + "&" + getFilterValues();
            }

            function subCatReadFromQS(arrSubCatDispTxt, arrSubCatClassAndID) {
                if (arrSubCatDispTxt && arrSubCatDispTxt.length > 0) {
                    $('.selectedFilterVal >  ul').append('<li class="grpsubcategory"> <ul></ul> </li>');
                    for (var i = 0, j = 0; i < arrSubCatDispTxt.length;) {
                        var dispTxt = decodeURIComponent(arrSubCatDispTxt[i].split('+').join(' '));
                        var className = arrSubCatClassAndID[j];
                        var itemId = arrSubCatClassAndID[j + 1];
                        j += 2;
                        i++;
                        $('.selectedFilterVal > ul .grpsubcategory ul').append('<li class="' + className + '">' + dispTxt + '<span indexname="' + GetSubCatIndexnameFromClass(className) + '" indexvalue="' + itemId + '" class="crossBtn subcategory" data-count-theme="' + itemId + '" data-type="subcategory"></span></li>');
                    }
                }
            }

            function AllSubPracticeReadFromQS(arrSubPracDispTxt, arrSubPracIdAndParentID, subPracticClass, parentGrp) {
                if (arrSubPracDispTxt && arrSubPracDispTxt.length > 0 && subPracticClass) {
                    var subPracticClassLower = subPracticClass.toLowerCase();
                    var liClassName = 'grp' + subPracticClassLower;
                    $('.selectedFilterVal > ul').append('<li class="' + liClassName + '"> <ul> </ul> </li>');
                    for (var i = 0, j = 0; i < arrSubPracDispTxt.length;) {
                        var dispTxt = decodeURIComponent(arrSubPracDispTxt[i].split('+').join(' '));
                        var itemId = arrSubPracIdAndParentID[j];
                        var parentId = arrSubPracIdAndParentID[j + 1];
                        j += 2;
                        i++;
                        $('.selectedFilterVal > ul .' + liClassName + ' ul').append('<li class="' + subPracticClass + '">' + dispTxt + '<span indexname="' + GetSubPracticeIndexnameFromClass(subPracticClass) + '" indexvalue="' + itemId + '" class="crossBtn ' + subPracticClassLower + '" data-count-theme="' + itemId + '" data-type="' + subPracticClassLower + '" parentid="' + parentId + '" parentgroup="' + parentGrp + '"></span></li>');
                    }
                }
            }

            function GetSubCatIndexnameFromClass(className) {
                var returnval = '';
                if (className) {

                    switch (className) {
                        case 'Sector':
                            returnval = 'sectors_sm';
                            break;
                        case 'Service':
                            returnval = 'practices_sm';
                            break;
                        case 'Region':
                            returnval = 'region_sm';
                            break;
                        case 'Office':
                            returnval = 'personofficeitem_sm';
                            break;
                    }
                }
                return returnval;
            }

            function GetSubPracticeIndexnameFromClass(className) {
                var returnval = '';
                if (className) {

                    switch (className) {
                        case 'SubSector':
                            returnval = 'sub_sectors_sm';
                            break;
                        case 'SubService':
                            returnval = 'sub_practices_sm';
                            break;
                        case 'SubRegion':
                            returnval = 'sub_region_sm';
                            break;
                    }
                }
                return returnval;
            }

            function filterClick(currentObj, displayVal) {
                var currentObjId = currentObj.attr('indexvalue');
                var addInSelectionCriteria = true;
                $('span.crossBtn').each(function (i) {
                    var currValue = $(this).attr("indexvalue");
                    if (currValue == currentObjId) {
                        addInSelectionCriteria = false;
                        return;
                    }
                });
                if (addInSelectionCriteria == true) {
                    var name = currentObj.attr('name');

                    var dataTitle = currentObj.attr('datatitle');
                    var parentId = "";
                    parentId = currentObj.attr('parentId');

                    var parentGroup = "";
                    parentGroup = currentObj.attr('parentgroup');

                    var parentText = currentObj.attr('parentText');

                    var dataVal = '';
                    var dataType = currentObj.attr('data-type');
                    var datahtmlVal = currentObj.html();
                    var subStrindexPos = datahtmlVal.lastIndexOf('(');
                    var dataShowhtml = datahtmlVal;

                    if (subStrindexPos > 0) {
                        dataShowhtml = datahtmlVal.substr(0, datahtmlVal.lastIndexOf('('));
                    }
                    if (displayVal != null && displayVal != '') {
                        dataVal = displayVal;
                        if (plugin_bioNew.sector != null && plugin_bioNew.sector) {
                            parentId = plugin_bioNew.sector;
                        } else if (plugin_bioNew.practice != null && plugin_bioNew.practice) {
                            parentId = plugin_bioNew.practice;
                        }
                        else if (plugin_bioNew.region != null && plugin_bioNew.region) {
                            parentId = plugin_bioNew.region;
                        }
                    } else {
                        if (parentText != null && parentText != '') {
                            dataVal = parentText.replace(/_/g, ' ') + " : ";
                        }
                        dataVal = dataVal + dataShowhtml;
                    }


                    var dataId = currentObj.attr('id');
                    var indexName = "";
                    var indexValue = "";
                    if (currentObj.attr('indexname') != null && currentObj.attr('indexvalue') != null) {
                        indexName = currentObj.attr('indexname');
                        indexValue = currentObj.attr('indexvalue');
                    }

                    var displayMode = "s";
                    if (currentObj.attr('data-display')) {
                        displayMode = currentObj.attr('data-display');
                    }
                    if (displayMode == "s") {
                        $('.' + dataType).each(function () {
                            var dataCounttemp = $(this).attr('data-count-theme');
                            $('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
                            $(this).parent().remove();
                        });
                    }

                    if (displayVal == null || displayVal == '') {
                        FilterRemove(dataType, currentObj);
                    }

                    ///*for remove */

                    if ($('.selectedFilterVal > ul').find(".grp" + dataType).length == 0) {
                        var isAdded = false;
                        if (dataType == 'subcategory') {
                            if ($('.selectedFilterVal > ul').find(".grpcategory").length != 0) {
                                $('.selectedFilterVal > ul > .grpcategory').after("<li class='grp" + dataType + "'><ul></ul></li>");
                                isAdded = true;
                            }
                        }
                        if (!isAdded) {
                            $('.selectedFilterVal > ul').append("<li class='grp" + dataType + "'><ul></ul></li>");
                        }
                    }

                    var sub = "none";
                    if (currentObj.attr('data-sub')) {
                        sub = currentObj.attr('data-sub');
                    }

                    if (dataType == "subservice" || dataType == "subsector" || dataType == "subregion") {
                        name = "";
                    } else {
                        name = name.replace(/_/g, ' ');
                    }


                    if (sub != "none") {
                        if ($('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub).length == 0) {
                            if (name != null && name != '') {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                            } else {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '" parentid="' + parentId + '" parentgroup="' + parentGroup + '"></span></li>');
                            }

                        } else if ($('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub).find("[indexvalue='" + indexValue + "']").length == 0) {
                            if (name != null && name != '') {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").find("." + sub + ":last").after('<li class="' + sub + '">' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                            } else {
                                $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li class="' + sub + '">' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '" parentid="' + parentId + '" parentgroup="' + parentGroup + '"></span></li>');
                            }
                        }
                    } else {
                        $('.selectedFilterVal > ul').find(".grp" + dataType).find("ul").append('<li>' + name + ' : ' + dataVal + '<span indexname="' + indexName + '" indexvalue="' + indexValue + '" class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
                    }

                    //To hide current item
                    currentObj.parent().hide();

                    check();

                }
            }

            function SubGroupFilterRemove(dataType) {
                $('.' + dataType).parent().remove();
                $('.grp' + dataType).remove();
                $('.by-' + dataType).find('ul li').remove();
                $('.by-' + dataType).hide();

            }

            function FilterRemove(dataType, currentObj) {
                /*for remove */
                var ctr = $.inArray(dataType, dataSeq);
                if (ctr > -1) {
                    for (var i = (dataSeq.length - 1) ; i > ctr; i--) {
                        var dataType1 = dataSeq[i];
                        $('.' + dataType1).each(function () {
                            $(this).parent().remove();
                        });
                        $('.grp' + dataType1).remove();
                        $('.by-' + dataType1).find('ul li').remove();
                        $('.by-' + dataType1).hide();
                    }
                }

                if (currentObj != null && currentObj.attr("parentgroup") == null) {
                    $('ul.subCatItem').each(function (i) {
                        $(this).hide();
                    });
                }

                /*for remove */
            }
            $("#generalOptions").change(function () {
                SortOptionChange("#generalOptions");
            });


            $("#ddlPageSize_Global").change(function () {
                PageSizeChange("#ddlPageSize_Global");
            });


            function SortOptionChange(divId) {
                var oldSelectedval = $('#GlobleSearchSortingValue').val();
                if (oldSelectedval != $(divId).val()) {
                    $('#GlobleSearchSortingValue').val($(divId).val());
                    plugin_bioNew.datasource.vm.searchResults.removeAll();
                    plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                    plugin_bioNew.loadFacets = false;
                    plugin_bioNew.pagingInfo.isLoadPagination = true;
                    plugin_bioNew.pagingInfo.isSortingClick = true;
                    plugin_bioNew.loadSearchResults(1, "");

                }
            }

            function PageSizeChange(divId) {
                var oldSelectedval = $('#oldSelectedPageSize_Global').val();
                if (oldSelectedval != $(divId).val()) {
                    $('#oldSelectedPageSize_Global').val($(divId).val());
                    plugin_bioNew.datasource.vm.searchResults.removeAll();
                    plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                    plugin_bioNew.loadFacets = false;
                    plugin_bioNew.pagingInfo.isLoadPagination = true;
                    plugin_bioNew.loadSearchResults(1, "");

                }
            }
            function ResetSortDDLOption() {
                var divs = "generalOptions";
                var splitDivsArr = divs.split(',');
                $.each(splitDivsArr, function (index, value) {
                    /*console.log(index + ": " + value);*/

                    $("." + value).hide();
                    var divOfDll = "#" + value;

                    var userTextkey = $("#ul" + value + " ul.listing li:first").attr('data');
                    $("#ul" + value + " ul.listing li").removeAttr("class");
                    $("#ul" + value + " ul.listing li:first").attr("class", "selected");
                    $(divOfDll).prop('selectedIndex', 0);

                    var userText = $(divOfDll + " option:selected").text();
                    $('#' + value + 'Div' + " .main #ul" + value + " .stylish-select-left .stylish-select-right").text(userText);

                });
                var userTextkey1 = $("#ulgeneralOptions ul.listing li:first").attr('data');
                $('#GlobleSearchSortingValue').val(userTextkey1);


            }

            function check() {
                var numItem = $('.selectedFilterVal ul li').length;
                numItem == 0 ? $('.selection-area').hide() : $('.selection-area').show();
            }
            ko.applyBindings(plugin_bioNew.datasource.vm);

            ResetPagingInfo();


        }

        plugin_bioNew.loadPagingResult = function (data, event) {
            plugin_bioNew.datasource.vm.searchResults.removeAll();
            plugin_bioNew.pagingInfo.isLoadPagination = false;
            plugin_bioNew.loadFacets = false;
            plugin_bioNew.pagingInfo.isFirstClick = false;
            plugin_bioNew.pagingInfo.isLastClick = false;
            plugin_bioNew.pagingInfo.isNextClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = false;
            plugin_bioNew.pagingInfo.isSortingClick = true;
            plugin_bioNew.pagingInfo.currentPage = data.pageNo;
            plugin_bioNew.loadSearchResults(data.pageNo, "");


        };


        // for first click
        plugin_bioNew.pgFirstclick = function (data, event) {
            plugin_bioNew.datasource.vm.searchResults.removeAll();
            plugin_bioNew.pagingInfo.isLoadPagination = true;
            plugin_bioNew.loadFacets = false;
            plugin_bioNew.pagingInfo.isFirstClick = true;
            plugin_bioNew.pagingInfo.isLastClick = false;
            plugin_bioNew.pagingInfo.isNextClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = false;
            plugin_bioNew.pagingInfo.isSortingClick = true;
            plugin_bioNew.pagingInfo.currentPage = plugin_bioNew.pagingInfo.pagingFirstCount;
            plugin_bioNew.loadSearchResults(plugin_bioNew.pagingInfo.pagingFirstCount, "");
            plugin_bioNew.pagingInfo.isFirstClick = false;
        };

        // for last click
        plugin_bioNew.pgLastclick = function (data, event) {
            plugin_bioNew.datasource.vm.searchResults.removeAll();
            plugin_bioNew.pagingInfo.isLoadPagination = true;
            plugin_bioNew.loadFacets = false;
            plugin_bioNew.pagingInfo.isLastClick = true;
            plugin_bioNew.pagingInfo.isFirstClick = false;
            plugin_bioNew.pagingInfo.isNextClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = false;
            plugin_bioNew.pagingInfo.isSortingClick = true;
            plugin_bioNew.pagingInfo.currentPage = plugin_bioNew.pagingInfo.pagingLastCount;
            plugin_bioNew.loadSearchResults(plugin_bioNew.pagingInfo.pagingLastCount, "");
            plugin_bioNew.pagingInfo.isLastClick = false;
        };

        // for Next click
        plugin_bioNew.pgNextclick = function (data, event) {
            plugin_bioNew.datasource.vm.searchResults.removeAll();
            plugin_bioNew.pagingInfo.isLoadPagination = true;
            plugin_bioNew.loadFacets = false;
            plugin_bioNew.pagingInfo.isNextClick = true;
            plugin_bioNew.pagingInfo.isLastClick = false;
            plugin_bioNew.pagingInfo.isFirstClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = false;
            plugin_bioNew.pagingInfo.isSortingClick = true;
            plugin_bioNew.pagingInfo.currentPage = plugin_bioNew.pagingInfo.pagingNextCount;
            plugin_bioNew.loadSearchResults(plugin_bioNew.pagingInfo.pagingNextCount, "");
            plugin_bioNew.pagingInfo.isNextClick = false;
        };

        // for Previous click
        plugin_bioNew.pgPreviousclick = function (data, event) {
            plugin_bioNew.datasource.vm.searchResults.removeAll();
            plugin_bioNew.loadFacets = false;
            plugin_bioNew.pagingInfo.isLoadPagination = true;
            plugin_bioNew.pagingInfo.isNextClick = false;
            plugin_bioNew.pagingInfo.isLastClick = false;
            plugin_bioNew.pagingInfo.isFirstClick = false;
            plugin_bioNew.pagingInfo.isPreviousClick = true;
            plugin_bioNew.pagingInfo.isSortingClick = true;
            plugin_bioNew.pagingInfo.currentPage = plugin_bioNew.pagingInfo.pagingPreviousCount;
            plugin_bioNew.loadSearchResults(plugin_bioNew.pagingInfo.pagingPreviousCount, "");
            plugin_bioNew.pagingInfo.isPreviousClick = false;
        };


        plugin_bioNew.loadSearchResults = function (pagenum, groupId, isfirstLoad) {
            window.location = getResultPageUrl(pagenum, groupId);
            return;
            if (!plugin_bioNew.processingRequest) {
                $(plugin_bioNew.settings.loaderDiv).show();
                plugin_bioNew.processingRequest = true;
                var urlResult = getServiceUrl(pagenum, groupId);

                $.getJSON(getServiceUrl(pagenum, groupId), function (data) {
                    var isScrolled = false;
                    if (data.TotalRecords > 0) {
                        $("#globalsearchresult1").show();
                        $("#searchHeader").show();
                        $("#refineResults").show();
                        if (isfirstLoad) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").addClass('hide');
                            $(".layout-mobile .related-source h1").removeClass('expand');
                        }
                        if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('hide')) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('hide').addClass('addHidePostProcessing');
                        }
                        plugin_bioNew.datasource.vm.total(data.TotalRecords);
                        plugin_bioNew.datasource.vm.searchText(data.SearchString);

                        plugin_bioNew.searchString = data.SearchString;

                        if (plugin_bioNew.loadFacets) {

                            var searchCategory = "";
                            if (plugin_bioNew.searchCategory != "") {
                                searchCategory = plugin_bioNew.searchCategory;
                            }

                            plugin_bioNew.datasource.vm.searchResultCategory(searchCategory.toLowerCase());

                            if (data.PersonFacets != null) {
                                var personFacetData = eval(data.PersonFacets); // this will convert your json string to a javascript object
                                var strPersonFacet = "";

                                for (var key in personFacetData) {
                                    if (personFacetData.hasOwnProperty(key)) { // this will check if key is owned by data object and not by any of it's ancestors
                                        var keyContent = "";
                                        var hasKeyResult = false;

                                        var keyValue = '';
                                        if ($("#hid" + key) != null) {
                                            keyValue = $("#hid" + key).val();
                                        }

                                        keyContent = keyContent + "<li><span class='subCat' id='span" + key.toLowerCase() + "'>" + keyValue + "</span><ul id=ul" + key + " class='subCatItem'>";
                                        keyValue = keyValue.replace(/ /g, "_");

                                        $.each(personFacetData[key], function (i, f) {
                                            var facetName = f.Name.split(':')[0];
                                            var facetValue = f.Name.split(':')[1];
                                            var indexName = f.Name.split(':')[2];
                                            var parentText = facetName.replace(/ /g, "_");
                                            var currValue = "";

                                            if ((key == "Sector" && data.SubSectorFacets != null)
                                            || (key == "Service" && data.SubServiceFacets != null)
                                            || (key == "Region" && data.SubRegionFacets != null)) {
                                                var subFacetContentToCheck = null;
                                                var currValue1 = "";
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubSectorFacets; }
                                                else if (data.SubServiceFacets != null && key == "Service" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubServiceFacets; }
                                                else if (data.SubRegionFacets != null && key == "Region" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubRegionFacets; }
                                                var hasResult1 = false;
                                                if (subFacetContentToCheck) {
                                                    $.each(subFacetContentToCheck, function (j, g) {
                                                        currValue1 = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue1 = $(this).attr("indexvalue");
                                                            if (currValue1 == subFacetValue) {
                                                                subFacetValue = "";

                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult1 = true;
                                                        }
                                                    });
                                                }

                                                if (!hasResult1) {
                                                    $('span.crossBtn').each(function (i) {
                                                        currValue = $(this).attr("indexvalue");
                                                        if (currValue == facetValue) {
                                                            facetValue = "";

                                                        }
                                                    });
                                                }
                                            }
                                            else {
                                                $('span.crossBtn').each(function (i) {
                                                    currValue = $(this).attr("indexvalue");
                                                    if (currValue == facetValue) {
                                                        facetValue = "";
                                                        return false;
                                                    }
                                                });
                                            }
                                            // }

                                            if (facetValue != "") {
                                                var parentId = facetValue;
                                                keyContent = keyContent + "<li><a name=" + key + " id=" + facetValue + " indexname=" + indexName + " indexvalue=" + facetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subcategory' data-sub='" + key + "' data-display='m' datatitle=" + facetName.toUpperCase() + ">";
                                                keyContent = keyContent + facetName.toUpperCase() + " (" + f.AggregateCount + ")";
                                                keyContent = keyContent + "</a></li>";
                                                hasKeyResult = true;
                                                //alert(data.SubSectorFacets);
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isSectorClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubSectorFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubSector' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subsector' data-sub='SubSector' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }


                                                if (data.SubServiceFacets != null && key == "Service" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isServiceClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubServiceFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubService' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subservice' data-sub='SubService' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }

                                                if (data.SubRegionFacets != null && key == "Region" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isRegionClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubRegionFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubRegion' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subregion' data-sub='SubRegion' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }
                                            }

                                        });
                                        keyContent = keyContent + "</ul></li>";
                                        if (hasKeyResult == false) {
                                            keyContent = "";
                                        }
                                        strPersonFacet = strPersonFacet + keyContent;

                                    }
                                }

                                if (strPersonFacet != "") {
                                    $('#sectionSubCategory').show();
                                    $('#refineResults').show();
                                    $("#ulPersonFacets").html(strPersonFacet);

                                    if (plugin_bioNew.searchCategory.toLowerCase() == "our people") {
                                        $('ul.subCatItem').each(function (i) {
                                            if (plugin_bioNew.clickedGroup != null && plugin_bioNew.clickedGroup != '') {
                                                if ($(this).attr("id").toLowerCase() == "ul" + plugin_bioNew.clickedGroup) {
                                                    $("#ul" + plugin_bioNew.clickedGroup).show();
                                                    $("#span" + plugin_bioNew.clickedGroup).addClass("expand");
                                                } else {
                                                    $(this).hide();
                                                }
                                            } else {
                                                $(".subCatItem").hide();
                                            }
                                        });

                                    }

                                } else {
                                    $('#sectionSubCategory').hide();
                                    $('#refineResults').hide();

                                }

                            }

                            if (data.Facets != null) {
                                $('#sectionCategory').show();

                                $.each(data.Facets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin_bioNew.datasource.vm.facets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });
                            }
                            else {
                                $('#sectionCategory').hide();
                            }


                            if (data.SubFacets != null) {
                                $('#sectionSubCategory').show();
                                $.each(data.SubFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin_bioNew.datasource.vm.subfacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });

                            }

                        }

                        if (data.PersonPaging.IsPaginationVisible) {
                            $('#pagingContainerSearchResults').show();
                            if (plugin_bioNew.pagingInfo.isLoadPagination) {
                                //if exists then remove first
                                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                                $.each(data.PersonPaging.PageNumbersFacets, function (i, f) {
                                    var isLarge = false;
                                    if (f > 99) {
                                        isLarge = true;
                                    }
                                    if (plugin_bioNew.pagingInfo.currentPage == f) {
                                        plugin_bioNew.datasource.vm.pagingFacets.push(new pagefacet(f, 2, isLarge));
                                    } else {
                                        plugin_bioNew.datasource.vm.pagingFacets.push(new pagefacet(f, 0, isLarge));
                                    }

                                });

                                //set last and first page number
                                plugin_bioNew.pagingInfo.pagingLastCount = data.PersonPaging.PageLastNumber;
                                plugin_bioNew.pagingInfo.pagingFirstCount = data.PersonPaging.PageFirstNumber;

                                //set last and previous index
                                plugin_bioNew.pagingInfo.pagingNextCount = data.PersonPaging.PageNextNumber;
                                plugin_bioNew.pagingInfo.pagingPreviousCount = data.PersonPaging.PagePreviousNumber;

                                //for set next and previous cursor

                                if (data.PersonPaging.IsNextCursorVisible) {
                                    $('#pagNextBtn').show();
                                    $('#pagLastBtn').show();
                                } else {
                                    $('#pagNextBtn').hide();
                                    $('#pagLastBtn').hide();
                                }

                                if (data.PersonPaging.IsPreviousCursorVisible) {
                                    $('#pagFirstBtn').show();
                                    $('#pagPrevBtn').show();
                                } else {
                                    $('#pagFirstBtn').hide();
                                    $('#pagPrevBtn').hide();
                                }
                            }


                        } else {
                            $('#pagingContainerSearchResults').hide();
                        }
                        var count = 1;
                        var css = 'peopleListingBox';
                        $.each(data.SearchResults, function (i, f) {
                            if (f.Title != null || f.Title != undefined) {
                                if (data.SearchResults.length == count)
                                    css = "peopleListingBox last";
                                var personUrlCustom = f.PersonURL;
                                if (plugin_bioNew.settings.isInternalBioRequest) {
                                    if (plugin_bioNew.settings.isInternalBioRequest == "true") {

                                        personUrlCustom = f.PersonURL + "?p=1&altbio=true";

                                        // TODO: manual appending of query string params is not recommended
                                        // Do this this instead:
                                        // var personURL = new URL(f.PersonURL, window.location.origin);
                                        // personURL.searchParams.set('p', '1');
                                        // personURL.searchParams.set('altbio', 'true');
                                    }
                                }
                                var personOfficetxt = f.PersonOfficeDisplay;
                                var personOfficePhonetxt = [];
                                var personOfficeDisplaytxt = [];
                                var personOfficeLanguage = [];
                                if (personOfficetxt) {
                                    $.each(personOfficetxt, function (index, value) {
                                       //   alert(index + ": " + value);
                                        var tempVar = value.split('||');
                                        if (tempVar) {
                                            personOfficeDisplaytxt.push(tempVar[0]);
                                            if (tempVar.length > 1)
                                            {
                                                personOfficePhonetxt.push(tempVar[1]);
                                            }
                                        }
                                    });

                                }
                                var currentLanguage = f.CurrentLanguage;
                                plugin_bioNew.datasource.vm.searchResults.push(new searchresult(f.Title, f.Description, f.Url, f.ArticleDate, f.name_for_search, f.PersonImage, f.PersonDesignation, personUrlCustom, f.PersonService, f.Email, f.Phone, personOfficeDisplaytxt, personOfficePhonetxt, f.PersonAlternateBio, f.itemId, currentLanguage, f.DesignationLanguage, personOfficeLanguage));
                            }

                        });
                        $("#divNoRecordFound").hide();
                        $(plugin_bioNew.settings.loaderDiv).hide();


                    } else if (data.TotalRecords == 0) {
                        $(plugin_bioNew.settings.loaderDiv).hide();
                        /* No records found -> show no records div */
                        //var showNoSearchResultMessage = "Your search did not match any results. ";
                        $("#globalsearchresult1").hide();
                        $("#divNoRecordFound").show();
                        //if (data.SearchString) {
                        //    showNoSearchResultMessage = "Your search - " + data.SearchString + "  - did not match any results. ";
                        //}
                        //$("#divNoRecordFound").text(showNoSearchResultMessage);
                        $("#searchHeader").hide();

                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 230
                            });
                        }
                        isScrolled = true;
                    }
                    if (data.TotalRecords > 0) {
                        $('.global-search-sorting').show();
                        $('.global-search-sorting-per-page').show();
                    }
                    else {
                        $('.global-search-sorting').hide();
                        $('.global-search-sorting-per-page').hide();
                    }
                    plugin_bioNew.processingRequest = false;

                    if ($("#sectionCategory li:visible").length == 0) {
                        $("#sectionCategory").hide();
                    } else {
                        $("#sectionCategory").show();
                    }
                    if ($("#sectionSubCategory li:visible").length == 0) {
                        $("#sectionSubCategory").hide();
                    } else {
                        $("#sectionSubCategory").show();
                    }

                    if (!($("#sectionCategory ul").has('li').length || $("#sectionSubCategory ul").has('li').length || $("#sectionThirdLevel ul").has('li').length || $("#sectionForthLevel ul").has('li').length)) {
                        $("#refineResults").hide();
                    } else {
                        $("#refineResults").show();

                    }

                    if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('addHidePostProcessing')) {
                        $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('addHidePostProcessing').addClass('hide');
                    }
                    if (!isScrolled) {
                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult1').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult1').offset().top - 230
                            });
                        }
                    }

                });
            }
        }

        plugin_bioNew.loadSearchResults1 = function (pagenum, groupId, isfirstLoad) {
            // window.location = getResultPageUrl(pagenum, groupId);
            //return;
            if (!plugin_bioNew.processingRequest) {
                $(plugin_bioNew.settings.loaderDiv).show();
                plugin_bioNew.processingRequest = true;
                var urlResult = getServiceUrl(pagenum, groupId);

                $.getJSON(urlResult, function (data) {
                    var isScrolled = false;
                    if (data.TotalRecords > 0) {
                        $("#globalsearchresult1").show();
                        $("#searchHeader").show();
                        $("#refineResults").show();
                        if (isfirstLoad) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").addClass('hide');
                            $(".layout-mobile .related-source h1").removeClass('expand');
                        }
                        if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('hide')) {
                            $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('hide').addClass('addHidePostProcessing');
                        }
                        plugin_bioNew.datasource.vm.total(data.TotalRecords);
                        plugin_bioNew.datasource.vm.searchText(data.SearchString);

                        plugin_bioNew.searchString = data.SearchString;

                        if (plugin_bioNew.loadFacets) {

                            var searchCategory = "";
                            if (plugin_bioNew.searchCategory != "") {
                                searchCategory = plugin_bioNew.searchCategory;
                            }

                            plugin_bioNew.datasource.vm.searchResultCategory(searchCategory.toLowerCase());

                            if (data.PersonFacets != null) {
                                var personFacetData = eval(data.PersonFacets); // this will convert your json string to a javascript object
                                var strPersonFacet = "";

                                for (var key in personFacetData) {
                                    if (personFacetData.hasOwnProperty(key)) { // this will check if key is owned by data object and not by any of it's ancestors
                                        var keyContent = "";
                                        var hasKeyResult = false;

                                        var keyValue = '';
                                        if ($("#hid" + key) != null) {
                                            keyValue = $("#hid" + key).val();
                                        }

                                        keyContent = keyContent + "<li><span class='subCat' id='span" + key.toLowerCase() + "'>" + keyValue + "</span><ul id=ul" + key + " class='subCatItem'>";
                                        keyValue = keyValue.replace(/ /g, "_");

                                        $.each(personFacetData[key], function (i, f) {
                                            var facetName = f.Name.split(':')[0];
                                            var facetValue = f.Name.split(':')[1];
                                            var indexName = f.Name.split(':')[2];
                                            var parentText = facetName.replace(/ /g, "_");
                                            var currValue = "";

                                            if ((key == "Sector" && data.SubSectorFacets != null)
                                            || (key == "Service" && data.SubServiceFacets != null)
                                            || (key == "Region" && data.SubRegionFacets != null)) {
                                                var subFacetContentToCheck = null;
                                                var currValue1 = "";
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubSectorFacets; }
                                                else if (data.SubServiceFacets != null && key == "Service" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubServiceFacets; }
                                                else if (data.SubRegionFacets != null && key == "Region" && plugin_bioNew.currIndexValue == facetValue)
                                                { subFacetContentToCheck = data.SubRegionFacets; }
                                                var hasResult1 = false;
                                                if (subFacetContentToCheck) {
                                                    $.each(subFacetContentToCheck, function (j, g) {
                                                        currValue1 = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue1 = $(this).attr("indexvalue");
                                                            if (currValue1 == subFacetValue) {
                                                                subFacetValue = "";

                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult1 = true;
                                                        }
                                                    });
                                                }

                                                if (!hasResult1) {
                                                    $('span.crossBtn').each(function (i) {
                                                        currValue = $(this).attr("indexvalue");
                                                        if (currValue == facetValue) {
                                                            facetValue = "";

                                                        }
                                                    });
                                                }
                                            }
                                            else {
                                                $('span.crossBtn').each(function (i) {
                                                    currValue = $(this).attr("indexvalue");
                                                    if (currValue == facetValue) {
                                                        facetValue = "";
                                                        return false;
                                                    }
                                                });
                                            }
                                            // }

                                            if (facetValue != "") {
                                                var parentId = facetValue;
                                                keyContent = keyContent + "<li><a name=" + key + " id=" + facetValue + " indexname=" + indexName + " indexvalue=" + facetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subcategory' data-sub='" + key + "' data-display='m' datatitle=" + facetName.toUpperCase() + ">";
                                                keyContent = keyContent + facetName.toUpperCase() + " (" + f.AggregateCount + ")";
                                                keyContent = keyContent + "</a></li>";
                                                hasKeyResult = true;
                                                //alert(data.SubSectorFacets);
                                                if (data.SubSectorFacets != null && key == "Sector" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isSectorClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubSectorFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubSector' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subsector' data-sub='SubSector' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }


                                                if (data.SubServiceFacets != null && key == "Service" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isServiceClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubServiceFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubService' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subservice' data-sub='SubService' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }

                                                if (data.SubRegionFacets != null && key == "Region" && plugin_bioNew.currIndexValue == facetValue && plugin_bioNew.isRegionClicked) {
                                                    var subFacetContent = "<ul style='margin-left: 26px;'>";
                                                    var hasResult = false;

                                                    $.each(data.SubRegionFacets, function (j, g) {
                                                        currValue = "";

                                                        var subFacetName = g.Name.split(':')[0];
                                                        var subFacetValue = g.Name.split(':')[1];
                                                        var subIndexName = g.Name.split(':')[2];

                                                        $('span.crossBtn').each(function (i) {
                                                            currValue = $(this).attr("indexvalue");
                                                            if (currValue == subFacetValue) {
                                                                subFacetValue = "";
                                                                return false;
                                                            }
                                                        });

                                                        if (subFacetValue != "") {
                                                            hasResult = true;
                                                            subFacetContent = subFacetContent + "<li><a name='SubRegion' parentGroup=" + key + " parentText=" + parentText + " parentId=" + parentId + " id=" + subFacetValue + " indexname=" + subIndexName + " indexvalue=" + subFacetValue + " href='javascript:void(0);' class='filterSublink siteSubCategory' data-type='subregion' data-sub='SubRegion' data-display='m' datatitle=" + subFacetName.toUpperCase() + ">";
                                                            subFacetContent = subFacetContent + subFacetName.toUpperCase() + " (" + g.AggregateCount + ")";
                                                            subFacetContent = subFacetContent + "</a></li>";
                                                        }
                                                    });

                                                    if (hasResult) {
                                                        subFacetContent = subFacetContent + "</ul>";
                                                        keyContent = keyContent + subFacetContent;
                                                    }


                                                }
                                            }

                                        });
                                        keyContent = keyContent + "</ul></li>";
                                        if (hasKeyResult == false) {
                                            keyContent = "";
                                        }
                                        strPersonFacet = strPersonFacet + keyContent;

                                    }
                                }

                                if (strPersonFacet != "") {
                                    $('#sectionSubCategory').show();
                                    $('#refineResults').show();
                                    $("#ulPersonFacets").html(strPersonFacet);

                                    if (plugin_bioNew.searchCategory.toLowerCase() == "our people") {
                                        $('ul.subCatItem').each(function (i) {
                                            if (plugin_bioNew.clickedGroup != null && plugin_bioNew.clickedGroup != '') {
                                                if ($(this).attr("id").toLowerCase() == "ul" + plugin_bioNew.clickedGroup) {
                                                    $("#ul" + plugin_bioNew.clickedGroup).show();
                                                    $("#span" + plugin_bioNew.clickedGroup).addClass("expand");
                                                } else {
                                                    $(this).hide();
                                                }
                                            } else {
                                                $(".subCatItem").hide();
                                            }
                                        });

                                    }

                                } else {
                                    $('#sectionSubCategory').hide();
                                    $('#refineResults').hide();

                                }

                            }

                            if (data.Facets != null) {
                                $('#sectionCategory').show();

                                $.each(data.Facets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin_bioNew.datasource.vm.facets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });
                            }
                            else {
                                $('#sectionCategory').hide();
                            }


                            if (data.SubFacets != null) {
                                $('#sectionSubCategory').show();
                                $.each(data.SubFacets, function (i, f) {
                                    var addInCriteria = true;
                                    $('span.crossBtn').each(function (i) {
                                        var currValue = $(this).attr("indexvalue");
                                        if (currValue.toLowerCase() == f.Name.toLowerCase()) {
                                            addInCriteria = false;
                                            return;
                                        }
                                    });
                                    if (addInCriteria == true) {
                                        plugin_bioNew.datasource.vm.subfacets.push(new category(f.Name.toUpperCase(), f.AggregateCount, f.Name.toUpperCase(), $.trim(f.Name.replace(" ", ""))));
                                    }
                                });

                            }


                        }

                        if (data.PersonPaging.IsPaginationVisible) {
                            $('#pagingContainerSearchResults').show();
                            if (plugin_bioNew.pagingInfo.isLoadPagination) {
                                //if exists then remove first
                                plugin_bioNew.datasource.vm.pagingFacets.removeAll();
                                var pagingNumber = 1;
                                var pagingNumQS = $.urlParam('pagenum');
                                if (pagingNumQS != 0) { plugin_bioNew.pagingInfo.currentPage = pagingNumQS; }

                                $.each(data.PersonPaging.PageNumbersFacets, function (i, f) {
                                    var isLarge = false;
                                    if (f > 99) {
                                        isLarge = true;
                                    }
                                    if (plugin_bioNew.pagingInfo.currentPage == f) {
                                        plugin_bioNew.datasource.vm.pagingFacets.push(new pagefacet(f, 2,isLarge));
                                    } else {
                                        plugin_bioNew.datasource.vm.pagingFacets.push(new pagefacet(f, 0, isLarge));
                                    }

                                });

                                //set last and first page number
                                plugin_bioNew.pagingInfo.pagingLastCount = data.PersonPaging.PageLastNumber;
                                plugin_bioNew.pagingInfo.pagingFirstCount = data.PersonPaging.PageFirstNumber;

                                //set last and previous index
                                plugin_bioNew.pagingInfo.pagingNextCount = data.PersonPaging.PageNextNumber;
                                plugin_bioNew.pagingInfo.pagingPreviousCount = data.PersonPaging.PagePreviousNumber;

                                //for set next and previous cursor

                                if (data.PersonPaging.IsNextCursorVisible) {
                                    $('#pagNextBtn').show();
                                    $('#pagLastBtn').show();
                                } else {
                                    $('#pagNextBtn').hide();
                                    $('#pagLastBtn').hide();
                                }

                                if (data.PersonPaging.IsPreviousCursorVisible) {
                                    $('#pagFirstBtn').show();
                                    $('#pagPrevBtn').show();
                                } else {
                                    $('#pagFirstBtn').hide();
                                    $('#pagPrevBtn').hide();
                                }
                            }


                        } else {
                            $('#pagingContainerSearchResults').hide();
                        }
                        var count = 1;
                        var css = 'peopleListingBox';
                        $.each(data.SearchResults, function (i, f) {
                            if (f.Title != null || f.Title != undefined) {
                                if (data.SearchResults.length == count)
                                    css = "peopleListingBox last";
                                var personUrlCustom = f.PersonURL;
                                if (plugin_bioNew.settings.isInternalBioRequest) {
                                    if (plugin_bioNew.settings.isInternalBioRequest == "true") {
                                        personUrlCustom = f.PersonURL + "?p=1&altbio=true";

                                        // TODO: manual appending of query string params is not recommended
                                        // Do this this instead:
                                        // var personURL = new URL(f.PersonURL, window.location.origin);
                                        // personURL.searchParams.set('p', '1');
                                        // personURL.searchParams.set('altbio', 'true');
                                    }
                                }
                                var personOfficetxt = f.PersonOfficeDisplay;
                                var personOfficePhonetxt = [];
                                var personOfficeDisplaytxt = [];
                                var personOfficeLanguage = [];
                                if (personOfficetxt) {
                                    $.each(personOfficetxt, function (index, value) {
                                        //alert(index + ": " + value);
                                        var tempVar = value.split('||');
                                        if (tempVar) {
                                            var length = 0;
                                            if (personOfficetxt.length > length) {
                                                if (tempVar[length] != personOfficeDisplaytxt[length]) {
                                                    personOfficeDisplaytxt.push(tempVar[length]);
                                                    if (tempVar.length > 1) {
                                                        personOfficePhonetxt.push(tempVar[length + 1]);
                                                        personOfficeLanguage.push(tempVar[length + 2]);

                                                    }
                                                }
                                                else if (tempVar.length > 1) {
                                                    tempVar[length] = "&nbsp; &nbsp;";
                                                    personOfficeDisplaytxt.push(tempVar[length]);
                                                    personOfficePhonetxt.push(tempVar[length + 1]);
                                                    personOfficeLanguage.push(tempVar[length + 2]);
                                                }

                                            }
                                        }
                                    });

                                }
                                var currentLanguage = f.CurrentLanguage;
                                plugin_bioNew.datasource.vm.searchResults.push(new searchresult(f.Title, f.Description, f.Url, f.ArticleDate, f.name_for_search, f.PersonImage, f.PersonDesignation, personUrlCustom, f.PersonService, f.Email, f.Phone, personOfficeDisplaytxt, personOfficePhonetxt, f.PersonAlternateBio, f.itemId, currentLanguage, f.DesignationLanguage , personOfficeLanguage));
                            }

                        });
                        $("#divNoRecordFound").hide();
                        $(plugin_bioNew.settings.loaderDiv).hide();
                        //$('#divPeopleListing > div > li:nth-child(odd)').addClass('bg-light-grey');
                        $('#divPeopleListing > div > li:nth-of-type(odd)').addClass('bg-light-grey');
                        //$(".people-search-result-inner-wraper ul li div.contact ul li").removeClass("bg-light-grey");
                        //$(".people-search-result-inner-wraper ul li div.details ul li").removeClass("bg-light-grey");


                    } else if (data.TotalRecords == 0) {
                        $(plugin_bioNew.settings.loaderDiv).hide();
                        /* No records found -> show no records div */
                        //var showNoSearchResultMessage = "Your search did not match any results. ";
                        $("#globalsearchresult1").hide();
                        $("#divNoRecordFound").show();
                        //if (data.SearchString) {
                        //    showNoSearchResultMessage = "Your search - " + data.SearchString + "  - did not match any results. ";
                        //}
                        //$("#divNoRecordFound").text(showNoSearchResultMessage);
                        $("#searchHeader").hide();

                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.bread-crumb').offset().top - 230
                            });
                        }
                        isScrolled = true;
                    }
                    if (data.TotalRecords > 0) {
                        $('.global-search-sorting').show();
                        $('.global-search-sorting-per-page').show();
                    }
                    else {
                        $('.global-search-sorting').hide();
                        $('.global-search-sorting-per-page').hide();
                    }

                    plugin_bioNew.processingRequest = false;

                    if ($("#sectionCategory li:visible").length == 0) {
                        $("#sectionCategory").hide();
                    } else {
                        $("#sectionCategory").show();
                    }
                    if ($("#sectionSubCategory li:visible").length == 0) {
                        $("#sectionSubCategory").hide();
                    } else {
                        $("#sectionSubCategory").show();
                    }

                    if (!($("#sectionCategory ul").has('li').length || $("#sectionSubCategory ul").has('li').length || $("#sectionThirdLevel ul").has('li').length || $("#sectionForthLevel ul").has('li').length)) {
                        $("#refineResults").hide();
                    } else {
                        $("#refineResults").show();

                    }

                    if ($(".layout-mobile .related-source h1").parent().find(".filterArea").hasClass('addHidePostProcessing')) {
                        $(".layout-mobile .related-source h1").parent().find(".filterArea").removeClass('addHidePostProcessing').addClass('hide');
                    }
                    if (!isScrolled) {
                        if ($(window).width() < 480) {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult1').offset().top - 170
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('#globalsearchresult1').offset().top - 230
                            });
                        }
                    }
                    $(".mail-id-image").each(function () {
                        $(this).css("display", "block");
                    });
                });
            }
        }


        var getServiceUrl = function (pagenum, groupId) {
            var url = "/api/custom/biosearchnew/execute?";
            //var searchString = plugin_bioNew.searchString ? plugin_bioNew.searchString : $.urlParam('SearchString');
            var query = [];
            if (pagenum) {
                query.push("pagenum=" + pagenum);
            }
            query.push('isInternalBioRequest=false')
            var sortingDivOption = $('#GlobleSearchSortingValue').val();
            if (sortingDivOption) {
                query.push("SortingField=" + sortingDivOption);
            }
            //if (searchString) {
            //    query.push("SearchString=" + searchString);
            //}
            if (plugin_bioNew.pagingInfo.isLastClick) {
                query.push("isLastClick=true");
            }
            else if (plugin_bioNew.pagingInfo.isFirstClick) {
                query.push("isFirstClick=true");
            }
            else if (plugin_bioNew.pagingInfo.isNextClick) {
                query.push("isNextClick=true");
                query.push("pagingNextCount=" + plugin_bioNew.pagingInfo.pagingNextCount);
            }
            else if (plugin_bioNew.pagingInfo.isPreviousClick) {
                query.push("isPreviousClick=true");
                query.push("pagingPreviousCount=" + plugin_bioNew.pagingInfo.pagingPreviousCount);
            }
            if (plugin_bioNew.pagingInfo.isViewAllClick) {
                query.push("isViewAllClick=true");
            }
            if (plugin_bioNew.isSectorClicked) {
                query.push("isSectorClick=true");
            }
            else if (plugin_bioNew.isServiceClicked) {
                query.push("isServiceClick=true");
            }
            else if (plugin_bioNew.isRegionClicked) {
                query.push("isRegionClick=true");
            }
            if (plugin_bioNew.currIndexValue != null && plugin_bioNew.currIndexValue != "") {
                query.push("currentGroup=" + plugin_bioNew.currIndexValue);
            }
           
            if (plugin_bioNew.enforcelanguage != null && plugin_bioNew.enforcelanguage != "") {
                query.push("enforce_language=" + plugin_bioNew.enforcelanguage);
                query.push("language_to_enforce=" + plugin_bioNew.languagetoenforce);
            }

            if (plugin_bioNew.publicationType != null && plugin_bioNew.publicationType != "") {
                query.push("pubType=" + plugin_bioNew.publicationType);
            }
            if (plugin_bioNew.publicationYear != null && plugin_bioNew.publicationYear != "") {
                query.push("pubYear=" + plugin_bioNew.publicationYear);
            }
            if (plugin_bioNew.loadCategories) {
                query.push("loadCategories=true");
            }
            if (plugin_bioNew.searchCategory != null && plugin_bioNew.searchCategory != "") {
                query.push("param_sitecontentcategory=" + plugin_bioNew.searchCategory);
            }
            if (plugin_bioNew.searchSubCategory != null && plugin_bioNew.searchSubCategory != "") {
                query.push("param_sitecontentsubcategory=" + plugin_bioNew.searchSubCategory);
            }
            //for page size
            var pageSizeValue = $('#oldSelectedPageSize_Global').val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);
            }
            $('.qsFields').each(function (i) {
                if ($(this).val() && $(this).val() != '')
                { query.push($(this).attr('id') + "=" + $(this).val()); }
            })
            return url + query.join("&") + "&" + getFilterValues();
        };

        var getResultPageUrl = function (pagenum, groupId) {
            var url = $('#bioNewPageURL').val() + "?";
            var query = [];
            if (pagenum) {
                query.push("pagenum=" + pagenum);
            }
            var sortingDivOption = $('#GlobleSearchSortingValue').val();
            console.log(sortingDivOption);
            if (sortingDivOption) {
                query.push("sortingqs=" + sortingDivOption);
            }
            //for page size
            var pageSizeValue = $('#oldSelectedPageSize_Global').val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);
            }

            if (plugin_bioNew.pagingInfo.isLastClick) {
                query.push("pagingchangeclick=isLastClick");
            }
            else if (plugin_bioNew.pagingInfo.isFirstClick) {
                query.push("pagingchangeclick=isFirstClick");
            }
            else if (plugin_bioNew.pagingInfo.isNextClick) {
                query.push("pagingchangeclick=isNextClick");
            }
            else if (plugin_bioNew.pagingInfo.isPreviousClick) {
                query.push("pagingchangeclick=isPreviousClick");
            }
            if (plugin_bioNew.pagingInfo.isViewAllClick) {
                query.push("isViewAllClick=true");
            }
            if (plugin_bioNew.isSectorClicked) {
                query.push("subCategoryTypeClick=isSectorClick");
            }
            else if (plugin_bioNew.isServiceClicked) {
                query.push("subCategoryTypeClick=isServiceClick");
            }
            else if (plugin_bioNew.isRegionClicked) {
                query.push("subCategoryTypeClick=isRegionClick");
            }
            $('.qsFields1').each(function (i) {
                if ($(this).val() && $(this).val() != '')
                { query.push($(this).attr('id') + "=" + $(this).val()); }
            });
            if (plugin_bioNew.currIndexValue != null && plugin_bioNew.currIndexValue != "") {
                query.push("currentGroup=" + plugin_bioNew.currIndexValue);
            }

            if (plugin_bioNew.enforcelanguage != null && plugin_bioNew.enforcelanguage != "") {
                query.push("enforce_language=" + plugin_bioNew.enforcelanguage);
            }

            if (plugin_bioNew.languagetoenforce != null && plugin_bioNew.languagetoenforce != "") {
                query.push("language_to_enforce=" + plugin_bioNew.languagetoenforce);
            }
            if (plugin_bioNew.filterGroup != null && plugin_bioNew.filterGroup != "") {
                query.push("filtergroup=" + plugin_bioNew.filterGroup);
            }
            if (plugin_bioNew.loadCategories) {
                query.push("loadCategories=true");
            }
            if (plugin_bioNew.searchCategory != null && plugin_bioNew.searchCategory != "") {
                query.push("param_sitecontentcategory=" + encodeURIComponent(plugin_bioNew.searchCategory));
            }
            if (plugin_bioNew.searchSubCategory != null && plugin_bioNew.searchSubCategory != "") {
                query.push("param_sitecontentsubcategory=" + encodeURIComponent(plugin_bioNew.searchSubCategory));
            }
            $('.qsFields').each(function (i) {
                if ($(this).val() && $(this).val() != '')
                { query.push($(this).attr('id') + "=" + $(this).val()); }
            });

            return url + query.join("&") + "&" + getFilterValuesInQS();

        };

        var getFilterValuesInQS = function () {
            var subCatClassAndID = [];
            var subCatDispTxt = [];
            $('.grpsubcategory ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var classId = $(this).attr('class') + "," + $(this).find('span').attr('indexvalue');
                    subCatClassAndID.push(classId);
                    subCatDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subServiceIDAndParentID = [];
            var subServicDispTxt = [];
            $('.grpsubservice ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subServiceIDAndParentID.push(idAndParentID);
                    subServicDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subSectorIDAndParentID = [];
            var subSectorDispTxt = [];
            $('.grpsubsector ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subSectorIDAndParentID.push(idAndParentID);
                    subSectorDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var subRegionIDAndParentID = [];
            var subRegionDispTxt = [];
            $('.grpsubregion ul li').each(function (i) {
                if ($(this).attr('class')) {
                    var idAndParentID = $(this).find('span').attr('indexvalue') + "," + $(this).find('span').attr('parentid');
                    subRegionIDAndParentID.push(idAndParentID);
                    subRegionDispTxt.push(encodeURIComponent($(this).text()));
                }
            });

            var filters = {};
            if (subCatClassAndID.length > 0) {
                filters['subCatInfo'] = [];
                filters['subCatInfo'].push(subCatClassAndID);
            }
            if (subCatDispTxt.length > 0) {
                filters['subCatText'] = [];
                filters['subCatText'].push(subCatDispTxt);
            }
            if (subServiceIDAndParentID.length > 0) {
                filters['subServiceInfo'] = [];
                filters['subServiceInfo'].push(subServiceIDAndParentID);
            }
            if (subServicDispTxt.length > 0) {
                filters['subServiceText'] = [];
                filters['subServiceText'].push(subServicDispTxt);
            }
            if (subSectorIDAndParentID.length > 0) {
                filters['subSectorInfo'] = [];
                filters['subSectorInfo'].push(subSectorIDAndParentID);
            }
            if (subSectorDispTxt.length > 0) {
                filters['subSectorText'] = [];
                filters['subSectorText'].push(subSectorDispTxt);
            }
            if (subRegionIDAndParentID.length > 0) {
                filters['subRegionInfo'] = [];
                filters['subRegionInfo'].push(subRegionIDAndParentID);
            }
            if (subRegionDispTxt.length > 0) {
                filters['subRegionText'] = [];
                filters['subRegionText'].push(subRegionDispTxt);
            }

            flatendedValues = [];

            for (var filterName in filters) {
                flatendedValues.push(filterName + "=" + filters[filterName].join(","));
            }
            return flatendedValues.join("&");
        };

        var getFilterValues = function () {
            var filters = {};
            $('span.crossBtn').each(function (i) {
                if ($(this).attr('indexname') != null && $(this).attr('indexname') != "") {
                    var indexName = $(this).attr('indexname');
                    if (!filters[indexName]) {
                        filters[indexName] = [];
                    }
                    filters[indexName].push($(this).attr('indexvalue'));
                }
            });

            flatendedValues = [];
            for (var filterName in filters) {
                flatendedValues.push(filterName + "=" + filters[filterName].join(","));
            }
            return flatendedValues.join("&");
        }
        plugin_bioNew.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.globalSearch1 = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('globalSearch')) {
                var plugin = new $.globalSearch1(this, options);
                $(this).data('globalSearch', plugin);
            }
        });
    }

    $.globalAutoSuggest1 = function (element, options) {
        /* defaults */
        var defaults = {
            itemId: ""
        };
        var $element = $(element), element = element;
        var plugin = this;
        plugin_bioNew.settings = {}

        /* init function */
        plugin_bioNew.init = function () {
            plugin_bioNew.settings = $.extend({}, defaults, options);
            $element.catcompleteGlobalSearch1({
                source: "/api/custom/biosearchnew/getautosuggestsearchresults",  //this route doesn't exist in the solution
                minLength: 2,
                select: function (event, ui) {
                    window.location = decodeURIComponent(ui.item.Url);
                }
            });

        }


        plugin_bioNew.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.globalAutoSuggest1 = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('globalAutoSuggest1')) {
                var plugin = new $.globalAutoSuggest1(this, options);
                $(this).data('globalAutoSuggest1', plugin);
            }
        });
    }

}(jQuery));

$(document).ready(function () {
    //Show or Replace Email addresses with Email Icon
    EmailIcon();

    $("#txtSiteSearch1").keypress(function (event) {
        var keycode = (event.keyCode ? event.keyCode : event.which);
        if (keycode == '13') {
            var searchStringValue = $.trim($(this).val());
            if (searchStringValue != "") {
                // var searchPageUrl=<%=SitecoreLibrary.GetItemUrl(ARMItempath.GlobalSearchPageItemId)%>;
                window.location = "/Our-People-Results?SearchString=" + encodeURIComponent(searchStringValue);
                // $('#txtGlobalSearch1').val = decodeURIComponent($.urlParam('SearchString').split('+').join(' '));
            } else {
                alert("Please enter any string to search");
                $("#txtSiteSearch1").attr("class", "searcherror");
                return false;
            }
        }
    });

});
$(window).resize(function () {
    EmailIcon();
});

function EmailIcon() {
    $("p.bio-mail-id").removeAttr("style");
    var maxWidth = $("p.bio-mail-id").width();
    var isBiggerLength = false;
    $(".bio-mail-id").each(function () {

        var anchorWidth = $(this).find('a').width();
        if (anchorWidth > maxWidth) {
            isBiggerLength = true;
        }
    });

    if (isBiggerLength === true) {
        $(".bio-mail-id").each(function () {
            $(this).css("display", "none");
            $(this).next().css("display", "block");
        });
    }
    else {
        $(".bio-mail-id-image").each(function () {
            $(this).prev().css("display", "block");
            $(this).css("display", "none");
        });
    }
}
$.urlParam = function (name) {
    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
        return 0;
    }
    return results[1] || 0;
};
